import React from 'react'
import {Link} from 'react-router-dom'
function General() {
    return (
        <>
          <div className="row bg-white m-3 p-3 position-relative">
        <div className="clear-sample-data-btn position-absolute text-center text-white" data-bs-toggle="modal" data-bs-target="#ClearData-modal">  
          <i className="mdi mdi-format-paint" style={{fontSize: 19}} />
          <span>Clear Sample data</span>
        </div>
        <h4 className="font-600 mt-3 dropzone px-2" style={{minHeight: '30px!important', borderColor: '#d2d2d2!important'}}>General Settings</h4>
        <div className="col-md-4 mt-3">
          <div className="d-flex align-items-start">
            <svg className="pe-2" style={{width: 55}} viewBox="0 0 512 512">
              <path style={{fill: '#2C3D4F'}} d="M214.2,122h84.4c5.5,0,10-4.5,10-10s-4.5-10-10-10h-84.4c-5.5,0-10,4.5-10,10S208.7,122,214.2,122z" />
              <path style={{fill: '#2C3D4F'}} d="M214.2,180.9h84.4c5.5,0,10-4.5,10-10s-4.5-10-10-10h-84.4c-5.5,0-10,4.5-10,10S208.7,180.9,214.2,180.9z" />
              <path style={{fill: '#2C3D4F'}} d="M214.2,240.8h84.4c5.5,0,10-4.5,10-10s-4.5-10-10-10h-84.4c-5.5,0-10,4.5-10,10S208.7,240.8,214.2,240.8z" />
              <path style={{fill: '#2C3D4F'}} d="M499.5,455.1H460v-62.5c0-6.9-5.6-12.5-12.5-12.5s-12.5,5.6-12.5,12.5v62.5h-68.1V34.6
  c0-6.9-5.6-12.5-12.5-12.5H158.6c-6.9,0-12.5,5.6-12.5,12.5v121.8H53.3c-6.9,0-12.5,5.6-12.5,12.5v286.3H12.5
  c-6.9,0-12.5,5.6-12.5,12.5s5.6,12.5,12.5,12.5h487c6.9,0,12.5-5.6,12.5-12.5S506.4,455.1,499.5,455.1L499.5,455.1z M146.1,455.1
  H65.8V181.3h80.3V455.1z M341.9,455.1H171.1V47.1h170.8V455.1z" />
              <path style={{fill: '#ff5969'}} d="M117.7,213.9H95.1c-5.5,0-10,4.5-10,10s4.5,10,10,10h22.6c5.5,0,10-4.5,10-10S123.2,213.9,117.7,213.9z" />
              <path style={{fill: '#ff5969'}} d="M117.7,262.5H95.1c-5.5,0-10,4.5-10,10s4.5,10,10,10h22.6c5.5,0,10-4.5,10-10S123.2,262.5,117.7,262.5z" />
              <path style={{fill: '#ff5969'}} d="M492,266.9c-12.4-22.6-28.1-35.1-44.4-35.1s-32,12.5-44.4,35.1c-9.9,18-16.2,40.6-16.2,57.4s6.2,34.8,17,49
  c12,15.7,27.5,24.4,43.7,24.4s31.8-8.7,43.7-24.4c10.8-14.2,17-32,17-49S501.8,284.9,492,266.9z M483.4,291.5
  c2.2,5.9,3.9,12,5.2,18.2l-10.3,0.8c-6.9,0.6-13.9-0.1-20.6-1.8l-9-2.4c-13.6-3.6-28-3.3-41.5,0.7c1.8-7.9,4.4-15.6,7.7-23
  c9.9-1.4,20-0.7,29.8,1.8l11,2.9c7.6,2,15.4,3,23.2,3C480.3,291.6,481.8,291.6,483.4,291.5L483.4,291.5z M404.9,324.3
  c0-0.3,0-0.7,0-1l6.2-1.9c10.9-3.3,22.6-3.5,33.6-0.6l9,2.4c6.4,1.7,12.9,2.5,19.5,2.5c2,0,4.1-0.1,6.1-0.2l10.7-0.9
  c0,6.8-1.2,13.6-3.5,20l-8.4,0.7c-6.9,0.6-13.9-0.1-20.6-1.8l-9-2.4c-13.5-3.5-27.7-3.3-41.2,0.6
  C405.8,336.1,404.9,330.2,404.9,324.3L404.9,324.3z M447.6,249.8c9.1,0,19.8,9.6,28.6,25.7c0.2,0.3,0.4,0.7,0.5,1
  c-5.9-0.1-11.7-1-17.5-2.5l-11-2.9c-8.2-2.1-16.6-3.2-25-3C431.1,256.6,439.9,249.8,447.6,249.8L447.6,249.8z M447.6,379.6
  c-12,0-25.5-9.5-34.1-24.1c10.3-2.7,21.1-2.7,31.4,0l9,2.4c6.4,1.7,12.9,2.5,19.5,2.5c1.7,0,3.5-0.1,5.2-0.2
  C470,372.1,458.2,379.6,447.6,379.6L447.6,379.6z" />
            </svg> 
            <div>
              <p className="font-600 mb-1">General</p>
              <ul className="d-flex list-unstyled flex-wrap ms-sm-n1">
                <li className="border-end border-light d-inline-block px-sm-1"><Link to="/company" className=" text-primary "> Company </Link></li>
                <li className="border-end border-light d-inline-block px-sm-1"><Link to="/locations" className=" text-primary "> Locations </Link></li>
                <li className="border-end border-light d-inline-block px-sm-1"><Link to="/departments" className=" text-primary "> Departments </Link></li>
                <li className="border-end border-light d-inline-block px-sm-1"><Link to="/sub-departments" className=" text-primary "> Sub Departments </Link></li>
                <li className="border-end border-light d-inline-block px-sm-1"><a href="teams.html" className=" text-primary "> Teams </a></li>
                <li className="border-end border-light d-inline-block px-sm-1"><Link to="/business-units" className=" text-primary "> Business Units </Link></li>
                <li className="border-end border-light d-inline-block px-sm-1"><Link to="/Preferences" className=" text-primary "> Preferences </Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-4 mt-3">
          <div className="d-flex align-items-start">
            <svg className="pe-2" style={{width: 35}} viewBox="0 0 512 512">
              <path style={{fill: '#ff5969'}} d="M266.6,421.3c-7.6-44.7-27.4-60.1-40.6-70.2c-8.9-6.9-11.4-9.1-11.4-15.9v-17.6c0-6.9-5.6-12.5-12.5-12.5
  s-12.5,5.6-12.5,12.5v17.6c0,6.7-2.6,9-11.4,15.9c-13.1,10.1-33,25.5-40.6,70.2c-0.1,0.7-0.2,1.4-0.2,2.1v28.4
  c0,6.9,5.6,12.5,12.5,12.5h104.4c6.9,0,12.5-5.6,12.5-12.5v-28.4C266.8,422.7,266.7,422,266.6,421.3z M202.1,260.4
  c-5.5,0-10-4.5-10-10v-42c-0.8-3.6-9.1-14-15.3-21.6c-12.7-15.8-28.6-35.6-36.4-57.7c-1.3-3.6-0.3-7.7,2.4-10.4
  c2.7-2.7,6.8-3.6,10.4-2.3c0.6,0.2,59.7,21.5,92.1,20.2c3.9-0.2,7.5,2,9.3,5.4s1.4,7.6-1,10.7c-3.8,5-8.7,10.7-13.8,16.7
  c-8.9,10.5-27.5,32.2-27.8,38.8v39.4l0,0v2.8C212.1,255.9,207.6,260.4,202.1,260.4z M192.1,208.1L192.1,208.1L192.1,208.1z
   M169.2,142.6c7,11.5,15.7,22.3,23.2,31.7c3.3,4.2,6.4,7.9,9,11.4c5.7-8.3,13.7-18,23.3-29.3c0.3-0.3,0.5-0.6,0.8-0.9
  C206.2,153,184.9,147.3,169.2,142.6z M433.2,184.6c-30.5-7.5-68.3-27.6-95.3-46.3c8.3,31.7,14.3,74.2,11,105.4l31.5-44.8
  L433.2,184.6z" />
              <path style={{fill: '#2C3D4F'}} d="M150.3,283c11.3-16.1,11.3-38.7,0-54.8l-35.7-51.1c-16-23-24.9-51.6-24.9-80.7v-38c0-6.9,5.6-12.5,12.5-12.5
  s12.5,5.6,12.5,12.5v38.2c0,24,7.2,47.5,20.4,66.3l35.7,51.1c17.1,24.5,17.1,58.8,0,83.4L135,348.5c-13.1,18.8-20.4,42.4-20.4,66.3
  v41c0.1,6.9-5.5,12.5-12.4,12.5s-12.5-5.6-12.5-12.5v-41c0-29,8.8-57.7,24.9-80.7L150.3,283z M289.6,177.3
  c16.1-23,24.9-51.7,24.9-80.7V58.4c0-6.9-5.6-12.5-12.5-12.5s-12.5,5.6-12.5,12.5v38.2c0,23.9-7.3,47.5-20.4,66.3L233.4,214
  c-17.1,24.6-17.1,58.9,0,83.4l35.7,51.1c13.2,18.8,20.4,42.3,20.4,66.3v41c0,6.9,5.6,12.5,12.5,12.5c7,0,12.5-5.6,12.6-12.4v-41
  c0-29.1-8.9-57.7-24.9-80.7L254,283.1c-11.3-16.1-11.3-38.7,0-54.8L289.6,177.3z M328.6,487H68.8c-6.9,0-12.5,5.6-12.5,12.5
  S61.9,512,68.8,512h259.8c6.9,0,12.5-5.6,12.5-12.5C341.1,492.6,335.5,487,328.6,487z M328.6,0H68.8c-6.9,0-12.5,5.6-12.5,12.5
  S61.9,25,68.8,25h259.8c6.9,0,12.5-5.6,12.5-12.5C341.1,5.6,335.5,0,328.6,0z M411.3,250.4c-0.1-6.6-0.6-13.3-1.6-19.8
  c-1.1-7.5-1.7-17.4-9.7-20.8c-2.7-1.2-5.9-1.4-8.7-0.4c-6,1.9-9.7,8-8.6,14.2c1.5,8.2,4.5,15.9,5.7,24.2c2.3,16.9,1.4,34.2-2.2,50.8
  c-3,14-7.9,27.5-14,40.4c-3.1,6.4-6.4,12.6-10.1,18.6c-2.5,4.1-9.4,11.4-3.5,15.7c2.6,1.9,6.3,1.4,8.2-1.2c0,0,1.5-2,4.1-5.5
  c22.5-30.2,37.8-65.8,40.2-103.7c0.1-1.2,0.1-2.4,0.2-3.5C411.3,256.4,411.3,253.4,411.3,250.4z" />
            </svg>
            <div>
              <p className="font-600 mb-1">Productivity</p>
              <ul className="d-flex list-unstyled flex-wrap ms-sm-n1">
                <li className="border-end border-light d-inline-block px-sm-1"><Link to="/email-notifications" className=" text-primary "> Email Notifications </Link></li>
                <li className="border-end border-light d-inline-block px-sm-1"><a href="canned-responses.html" className=" text-primary "> Canned Responses </a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-4 mt-3">
          <div className="d-flex align-items-start">
            <svg className="pe-2" style={{width: 35}} viewBox="0 0 512 512">
              <path style={{fill: '#ff5969'}} d="M507.7,428.4l-8.7-4.7c-5.9-3.2-8.1-10.6-4.9-16.4c0.8-1.6,2-2.9,3.5-4l8.3-6.1
  c3.2-2.3,4.1-6.6,2.3-10l-8.6-16.5c-1.8-3.4-5.7-5-9.3-3.9l-9.5,2.8c-6.4,2-13.1-1.6-15.1-8c-0.5-1.7-0.7-3.6-0.4-5.4l1.6-10.2
  c0.6-3.8-1.7-7.6-5.4-8.7l-17.8-5.6c-3.6-1.1-7.5,0.5-9.3,3.8l-6.1,11.3c-2.7,5.1-8.8,7.6-14.3,5.8c-2.3-0.7-4.3-2.1-5.8-4l-7.9-10
  c-2.3-2.9-6.5-3.8-9.8-2.1l-16.5,8.6c-3.5,1.8-5.1,5.9-3.8,9.6l3.3,9.8c2.2,6.2-1.1,13.1-7.3,15.3c-1.8,0.6-3.6,0.8-5.5,0.6
  l-9.8-1.1c-3.8-0.4-7.3,1.9-8.4,5.5l-5.6,17.8c-1.2,3.7,0.6,7.8,4.1,9.5l9.2,4.5c3.7,1.8,6.3,5.5,6.7,9.6c0.5,4.1-1.2,8.2-4.5,10.7
  l-7.7,6.2c-2.9,2.3-3.8,6.5-2.1,9.8l8.6,16.5c1.8,3.5,5.8,5.1,9.5,3.8l9.8-3.3c6.3-2.2,13.2,1.2,15.3,7.5c0.6,1.7,0.8,3.5,0.6,5.3
  l-1.1,9.8c-0.4,3.8,1.9,7.3,5.5,8.4l17.8,5.6c0.8,0.2,1.6,0.4,2.4,0.4c3,0,5.8-1.7,7.1-4.4l3.2-6.5c2.7-5.4,8.8-8,14.5-6.2
  c2.4,0.8,4.6,2.3,6.1,4.4l4.3,5.9c2.3,3.2,6.6,4.1,10,2.3l16.5-8.6c3.4-1.8,5-5.7,3.9-9.3l-2.8-9.5c-1.2-3.9-0.3-8.2,2.5-11.3
  c2.7-3.2,6.9-4.7,11-4l10.2,1.6c3.8,0.6,7.5-1.7,8.7-5.4l5.6-17.9C512.7,434.2,511,430.4,507.7,428.4z M458.4,479.3l1,3.2l-4.7,2.5
  l-0.3-0.4c-3.5-4.7-8.4-8.3-14-10.1c-13.1-4.2-27.4,1.9-33.4,14.3l-0.2,0.4l-5-1.6l0.4-3.3c1.5-13.3-6.6-25.8-19.4-29.8
  c-5.6-1.8-11.7-1.7-17.3,0.2l-3.3,1.1l-2.5-4.7l2.6-2.1c7.5-5.9,11.4-15.3,10.4-24.8s-6.8-17.8-15.4-22.1l-3.1-1.6l1.6-5.1l3.3,0.4
  c15.3,1.8,29.1-9.2,30.9-24.5c0.5-4.1,0-8.3-1.3-12.2l-1.1-3.3l4.7-2.5l3.9,4.9c9.5,12.1,27,14.2,39.1,4.6c3-2.4,5.5-5.3,7.3-8.6
  l2.9-5.5l5.1,1.6l-0.6,3.5c-2.3,15.2,8.1,29.4,23.3,31.7c4.1,0.6,8.3,0.3,12.2-0.8l3.2-1l2.5,4.7l-2.8,2.1c-12.4,9.1-15.1,26.6-6,39
  c2.4,3.3,5.6,6.1,9.3,8l2.9,1.5l-1.6,5l-3.5-0.6c-9.5-1.5-19.1,2-25.3,9.3C457.9,460,455.7,470,458.4,479.3L458.4,479.3z M436.7,389
  c-17.9-6.9-38.1,2-45,19.9s2,38.1,19.9,45c0.4,0.1,0.7,0.3,1.1,0.4c8.6,3.2,18.2,2.9,26.6-1c17.5-8.1,25.1-28.8,17-46.2
  C452.4,398.7,445.4,392.2,436.7,389z M442.5,428.2c-3.6,9.9-14.5,14.9-24.4,11.4c-9.9-3.6-14.9-14.5-11.4-24.4
  c2.7-7.5,9.8-12.5,17.8-12.5c2.2,0,4.4,0.4,6.5,1.2c9.8,3.5,15,14.2,11.5,24C442.6,428,442.5,428.1,442.5,428.2z" />
              <path style={{fill: '#2C3D4F'}} d="M226.8,5.3C101.7,5.3,0,107.1,0,232.1S101.7,459,226.8,459c33.9,0,67.4-7.6,98-22.3
  c-1.1-6.1-1.7-12.3-1.7-18.5c0-2.9,0.1-5.7,0.4-8.6C253.1,448,166.7,441,103.4,391.9c32.9-3.2,59.5-14.4,79.2-33.2
  c14.8-14.4,25.3-32.7,30.4-52.7c7.1,3.9,13,5.9,16.8,6.2l1.1,0.1l1.1-0.1c4-0.4,10.3-2.5,17.9-6.8c5,20.3,15.5,38.7,30.5,53.3
  c13.1,12.5,29.2,21.6,48.1,27.3c2.9-8.3,6.8-16.3,11.7-23.6c-16.8-4.4-30.7-11.7-41.7-22.1c-19.7-18.8-24.5-42.8-25.7-52.4
  c3.7-3.5,7.2-7.2,10.5-11.1c21.2-24.7,33.3-54.1,33.3-80.5c0-28.6-8.9-53-25.6-70.4c-15.5-16.1-36.8-25-60.2-25s-44.7,8.9-60.2,25
  c-16.8,17.4-25.6,41.8-25.6,70.4c0,26.4,12.2,55.8,33.4,80.5c3.9,4.5,7.8,8.5,11.6,12.1c-1.4,10.2-6.5,33.2-25.6,51.4
  c-19.7,18.8-49.3,27.7-87.8,26.7c-33.5-36.9-52-85-51.9-134.9C24.7,120.7,115.4,30,226.8,30s202.1,90.7,202.1,202.1
  c0.1,30.2-6.7,60.1-19.9,87.3c4.5-0.6,9.1-1,13.7-1c4.5,0,9,0.3,13.4,0.9c11.6-27.6,17.5-57.3,17.5-87.3
  C453.6,107.1,351.9,5.3,226.8,5.3z M170.7,196.3c0-21.8,6.4-40,18.4-52.5c10.6-11,25.4-17,41.6-17s31,6,41.6,17
  c12.1,12.5,18.4,30.7,18.4,52.5c0,20.4-9.9,43.6-27.1,63.7c-14.5,16.8-27.9,24-32.9,26c-5-1.9-18.4-9.1-32.9-26
  C180.6,239.9,170.7,216.7,170.7,196.3L170.7,196.3z" />
            </svg>
            <div>
              <p className="font-600 mb-1">Roles &amp; Privileges</p>
              <ul className="d-flex list-unstyled flex-wrap ms-sm-n1">
                <li className="border-end border-light d-inline-block px-sm-1"><Link to="/manage/roles" className=" text-primary "> Manage Roles </Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-4 mt-3">
          <div className="d-flex align-items-start">
            <svg className="pe-2" style={{width: 35}} viewBox="0 0 512 512">
              <path style={{fill: '#2C3D4F'}} d="M216.6,405.8H75.2c-5.5,0-10-4.5-10-10s4.5-10,10-10h141.5c5.5,0,10,4.5,10,10
  S222.2,405.8,216.6,405.8z M298.7,217.2c-9.7,26-28.8,46.6-53.7,58.2l-0.1,0.1c-14,6.3-28.6,9.5-43.2,9.5c-11.6,0-23.6-2.2-35.6-6.5
  l-0.1,0c-25.9-9.7-46.6-28.8-58.1-53.7c-11.6-25.1-12.8-53.1-3.3-79c19.8-53.3,79.4-80.8,132.7-61.2l0,0
  c25.7,9.4,46.3,28.5,58.2,53.7C307,163.3,308.2,191.3,298.7,217.2z M236.6,257.3c2.3-1.1,4.5-2.3,6.7-3.5
  c-13.5-4.7-24.4-14.5-31.7-28.7c-1.7,0.9-3,1.5-3.8,1.7c-2.1,0.8-4.2,1.2-6.4,1.2s-4.3-0.4-6.4-1.2c-0.7-0.3-1.9-0.8-3.5-1.6
  c-7.2,14-18.1,23.8-31.4,28.5c4,2.3,8.3,4.3,12.7,6c9.7,3.5,19.6,5.3,28.7,5.3C213.4,264.9,225.1,262.4,236.6,257.3z M228.1,170
  c0-9.7-2.8-17.8-8.2-23.4c-4.7-4.9-11.3-7.6-18.5-7.6c-7.2,0-13.8,2.7-18.5,7.6c-5.3,5.6-8.2,13.7-8.2,23.4
  c0,9.1,4.4,19.4,12.1,28.4c6.5,7.5,12.4,10.7,14.6,11.6c2.2-0.8,8.2-4.1,14.6-11.6C223.7,189.4,228.1,179.1,228.1,170z M277.3,146.6
  c-9.6-20.3-26.3-35.8-47-43.4c-43-15.8-91.1,6.4-107.1,49.4c-7.7,20.8-6.7,43.5,2.6,63.7c3.9,8.4,9.1,16,15.3,22.5
  c11.5,0,26.6-4.4,35.6-24.7c-1.2-1.2-2.5-2.6-3.8-4.1c-10.6-12.4-16.4-26.6-16.4-40.1c0-14.4,4.6-26.8,13.2-35.9
  c8.1-8.4,19.3-13.1,31.5-13.1s23.4,4.6,31.5,13.1c8.6,9,13.2,21.4,13.2,35.8c0,13.5-5.8,27.7-16.4,40.1c-1.2,1.4-2.4,2.6-3.5,3.8
  c9,20.5,24.2,24.9,35.8,24.9c0.1,0,0.1,0,0.2,0c7.7-8.1,13.7-17.7,17.7-28.5C287.6,189.5,286.6,166.8,277.3,146.6z M354.4,453.9
  v33.9H35.1v-462h319.3v260c3.5-0.6,7.1-1,10.6-1c4.9,0,9.7,0.6,14.4,1.8V0.8H10.1v512h369.3v-58.9H354.4z" />
              <path style={{fill: '#ff5969'}} d="M250.6,341.9c0,5.5-4.5,10-10,10H78.5c-5.5,0-10-4.5-10-10s4.5-10,10-10h162.1
  C246.2,331.9,250.6,336.4,250.6,341.9z M487.1,394c0,24.2-19.4,43.9-43.3,43.9h-43h-7.7h-88.6c-3.7,0-7.3-0.5-10.7-1.5
  c-0.6-0.1-1.5-0.3-2.4-0.6c-20.8-6.1-35.4-25.6-35.4-47.5c0-24.4,17.8-44.7,41-48.7c7-10.5,18.3-17.6,31.1-19.3
  c8.1-11.9,21.8-19.6,36.7-19.6c8.8,0,17.3,2.6,24.4,7.3c6.7-2.3,13.7-3.5,20.9-3.5c33.3,0,60.7,25.5,63.9,57.9
  C482.4,370.7,487.1,382,487.1,394L487.1,394z M467.1,394.2V394c0-8.7-4.6-17.1-12.3-21.2l-0.4-0.4l0-0.7c0.1-0.9,0.1-1.9,0.1-2.9
  c0-24.4-19.8-44.2-44.2-44.2c-8.4,0-16.7,2.4-23.8,7l-0.9,0.6l-0.6-0.9c-4.5-6.6-12-10.5-20-10.5c-11.3,0-21.3,8-23.7,19.1l-0.3,1.2
  l-1.2-0.3c-2-0.5-4-0.7-6-0.7c-11.1,0-20.8,7.5-23.5,18.3l-0.3,1l-1-0.1c-1.3-0.2-2.6-0.2-3.8-0.2c-16.1,0-29.2,13.1-29.2,29.3
  c0,13.2,8.7,24.7,21.1,28.3c0.6,0.2,1.2,0.2,1.8,0.4c1.8,0.7,3.7,0.9,5.6,0.9h88.6h7.7h43C456.6,417.9,467,407.4,467.1,394.2z" />
            </svg>
            <div>
              <p className="font-600 mb-1">Account</p>
              <ul className="d-flex list-unstyled flex-wrap ms-sm-n1">
                <li className="border-end border-light d-inline-block px-sm-1"><Link to="/account" className=" text-primary "> Account </Link></li>
                <li className="border-end border-light d-inline-block px-sm-1"><a href="billing.html" className=" text-primary "> Plans &amp; Billings </a></li>
                <li className="border-end border-light d-inline-block px-sm-1"><a href="security.html" className=" text-primary "> Security </a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-4 mt-3">
          <div className="d-flex align-items-start">
            <svg className="pe-2" style={{width: 35}} viewBox="0 0 512 512">
              <path style={{fill: '#FF3366'}} d="M231.3,212c7.5,7.2,7.6,19.2,0.3,26.6l-28.3,28.8l-28.6-24.9l30.2-30.2
    C212.2,204.9,223.9,204.8,231.3,212L231.3,212z M305.5,286.1L305.5,286.1c-7.3-7.1-19-7-26.3,0.2L249,316.5l28.6,24.9l28.3-28.8
    C313.1,305.3,312.9,293.4,305.5,286.1z" />
              <path style={{fill: '#2C3D4F'}} d="M163.6,223.1c-4.7-4.7-12.3-4.7-17,0l-37.2,37.2c-36.4,36.4-39,93.8-8,133.3l-74,74
    c-4.7,4.7-4.7,12.3,0,17c2.3,2.3,5.4,3.5,8.5,3.5s6.1-1.2,8.5-3.5l74-74c17.7,13.9,39.3,21.5,62.2,21.5c26.9,0,52.1-10.5,71.1-29.5
    l37.2-37.2c4.7-4.7,4.7-12.3,0-17L163.6,223.1z M234.8,385.6c-14.5,14.5-33.7,22.4-54.2,22.4s-39.7-8-54.2-22.4l0,0
    c-29.9-29.9-29.9-78.5,0-108.3l28.7-28.7L263.5,357L234.8,385.6z M484.5,27.5c-4.7-4.7-12.3-4.7-17,0l-74,74
    c-39.5-31-96.9-28.4-133.3,8L223,146.7c-4.7,4.7-4.7,12.3,0,17L348.3,289c2.3,2.3,5.4,3.5,8.5,3.5s6.1-1.2,8.5-3.5l37.2-37.2
    c19-19,29.5-44.3,29.5-71.1c0-22.8-7.6-44.5-21.5-62.2l74-74C489.2,39.7,489.2,32.1,484.5,27.5z M385.6,234.8l-28.7,28.7
    L248.5,155.1l28.7-28.7c29.9-29.9,78.5-29.9,108.4,0l0,0c14.5,14.5,22.4,33.7,22.4,54.2S400.1,220.3,385.6,234.8z" />
            </svg>
            <div>
              <p className="font-600 mb-1">Integrate with other Apps</p>
              <ul className="d-flex list-unstyled flex-wrap ms-sm-n1">
                <li className="border-end border-light d-inline-block px-sm-1"><Link to="/integrations" className=" text-primary "> Integrations </Link></li>
                <li className="border-end border-light d-inline-block px-sm-1"><a href="#" className=" text-primary "> Apps </a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>   
        </>
    )
}

export default General
