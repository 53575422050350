import React from 'react'
import {NavLink,Link} from 'react-router-dom'
function Recruitment() {
    return (
        <>
            <div className="row bg-white m-3 p-3">
        <h4 className="font-600 dropzone px-2" style={{minHeight: '30px!important', borderColor: '#d2d2d2!important'}}>Recruitment and Talent Management</h4>
        <div className="col-md-4 mt-3">
          <div className="d-flex align-items-start">
            <svg className="pe-2" style={{width: 35}} viewBox="0 0 512 512">
              <path style={{fill: '#2C3D4F'}} d="M214.2,122h84.4c5.5,0,10-4.5,10-10s-4.5-10-10-10h-84.4c-5.5,0-10,4.5-10,10S208.7,122,214.2,122z" />
              <path style={{fill: '#2C3D4F'}} d="M214.2,180.9h84.4c5.5,0,10-4.5,10-10s-4.5-10-10-10h-84.4c-5.5,0-10,4.5-10,10S208.7,180.9,214.2,180.9z" />
              <path style={{fill: '#2C3D4F'}} d="M214.2,240.8h84.4c5.5,0,10-4.5,10-10s-4.5-10-10-10h-84.4c-5.5,0-10,4.5-10,10S208.7,240.8,214.2,240.8z" />
              <path style={{fill: '#2C3D4F'}} d="M499.5,455.1H460v-62.5c0-6.9-5.6-12.5-12.5-12.5s-12.5,5.6-12.5,12.5v62.5h-68.1V34.6
  c0-6.9-5.6-12.5-12.5-12.5H158.6c-6.9,0-12.5,5.6-12.5,12.5v121.8H53.3c-6.9,0-12.5,5.6-12.5,12.5v286.3H12.5
  c-6.9,0-12.5,5.6-12.5,12.5s5.6,12.5,12.5,12.5h487c6.9,0,12.5-5.6,12.5-12.5S506.4,455.1,499.5,455.1L499.5,455.1z M146.1,455.1
  H65.8V181.3h80.3V455.1z M341.9,455.1H171.1V47.1h170.8V455.1z" />
              <path style={{fill: '#ff5969'}} d="M117.7,213.9H95.1c-5.5,0-10,4.5-10,10s4.5,10,10,10h22.6c5.5,0,10-4.5,10-10S123.2,213.9,117.7,213.9z" />
              <path style={{fill: '#ff5969'}} d="M117.7,262.5H95.1c-5.5,0-10,4.5-10,10s4.5,10,10,10h22.6c5.5,0,10-4.5,10-10S123.2,262.5,117.7,262.5z" />
              <path style={{fill: '#ff5969'}} d="M492,266.9c-12.4-22.6-28.1-35.1-44.4-35.1s-32,12.5-44.4,35.1c-9.9,18-16.2,40.6-16.2,57.4s6.2,34.8,17,49
  c12,15.7,27.5,24.4,43.7,24.4s31.8-8.7,43.7-24.4c10.8-14.2,17-32,17-49S501.8,284.9,492,266.9z M483.4,291.5
  c2.2,5.9,3.9,12,5.2,18.2l-10.3,0.8c-6.9,0.6-13.9-0.1-20.6-1.8l-9-2.4c-13.6-3.6-28-3.3-41.5,0.7c1.8-7.9,4.4-15.6,7.7-23
  c9.9-1.4,20-0.7,29.8,1.8l11,2.9c7.6,2,15.4,3,23.2,3C480.3,291.6,481.8,291.6,483.4,291.5L483.4,291.5z M404.9,324.3
  c0-0.3,0-0.7,0-1l6.2-1.9c10.9-3.3,22.6-3.5,33.6-0.6l9,2.4c6.4,1.7,12.9,2.5,19.5,2.5c2,0,4.1-0.1,6.1-0.2l10.7-0.9
  c0,6.8-1.2,13.6-3.5,20l-8.4,0.7c-6.9,0.6-13.9-0.1-20.6-1.8l-9-2.4c-13.5-3.5-27.7-3.3-41.2,0.6
  C405.8,336.1,404.9,330.2,404.9,324.3L404.9,324.3z M447.6,249.8c9.1,0,19.8,9.6,28.6,25.7c0.2,0.3,0.4,0.7,0.5,1
  c-5.9-0.1-11.7-1-17.5-2.5l-11-2.9c-8.2-2.1-16.6-3.2-25-3C431.1,256.6,439.9,249.8,447.6,249.8L447.6,249.8z M447.6,379.6
  c-12,0-25.5-9.5-34.1-24.1c10.3-2.7,21.1-2.7,31.4,0l9,2.4c6.4,1.7,12.9,2.5,19.5,2.5c1.7,0,3.5-0.1,5.2-0.2
  C470,372.1,458.2,379.6,447.6,379.6L447.6,379.6z" />
            </svg> 
            <div>
              <p className="font-600 mb-1">Job Setup</p>
              <ul className="d-flex list-unstyled flex-wrap ms-sm-n1">
                <li className="border-end border-light d-inline-block px-sm-1"><NavLink to="/email" className=" text-primary "> Email </NavLink></li>
                <li className="border-end border-light d-inline-block px-sm-1"><NavLink to="/tags" className=" text-primary "> Tags </NavLink></li>
                <li className="border-end border-light d-inline-block px-sm-1"><a href="#" className=" text-primary "> Source </a></li>
                <li className="border-end border-light d-inline-block px-sm-1"><NavLink to="/vendors" className=" text-primary "> Vendors </NavLink></li>
                <li className="border-end border-light d-inline-block px-sm-1"><NavLink to="/candidate-reject-reason" className=" text-primary "> Candidate Reject Reasons </NavLink></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-4 mt-3">
          <div className="d-flex align-items-start">
            <svg className="pe-2" style={{width: 35}} viewBox="0 0 512 512"><g>
                <path style={{fill: '#2C3D4F'}} d="M256.2,504.3c-66.3,0-128.7-25.8-175.6-72.7S7.9,322.3,7.9,256S33.7,127.3,80.6,80.4
  C127.5,33.5,189.9,7.7,256.2,7.7c4.8,0,9.4,0.2,14.2,0.4l0.2,0l0.3,0c1.6,0,2.9,0.2,4.3,0.7l0.2,0.1c3.1,1.1,5.6,3.5,7.1,6.5
  c1.4,3.1,1.6,6.5,0.5,9.7C275.7,44.9,272,65.6,272,86.7c0,98.8,80.3,179.1,179.1,179.1c12.3,0,25-1.3,37.7-3.8
  c0.8-0.2,1.7-0.2,2.5-0.2c6,0,11.2,4.1,12.5,10.1c0.4,2.1,0.3,3.8-0.1,5.2l-0.3,1l-0.1,1.1c-5.7,61.3-33.9,118.2-79.4,160
  C378.1,481.1,318.5,504.3,256.2,504.3z M239.5,478.3c5.5,0.4,11,0.6,16.7,0.6c1.8,0,4.1,0,6.3-0.3l5.1-0.6l2.4-4.6
  c13.5-25.2,24.5-54,32.8-85.6l3-11.7l-12-0.8c-12.3-0.8-24.2-1.2-36.6-1.2c-16.5,0-33,0.8-49,2.5l-11.6,1.2l2.9,11.3
  c7.9,30.6,18.6,58.8,31.9,83.9l2.6,4.9L239.5,478.3z M328.6,388.7c-5.7,24.1-13.3,46.8-22.4,67.7l-8.6,19.6l20.6-6
  c35-10.2,67.2-29,93.2-54.3l11.3-11l-14.8-5.5c-21.5-8-44.3-14-67.8-18l-9.2-1.6L328.6,388.7z M101.5,416.4
  c22.9,22,49.7,38.7,79.6,49.4l21.1,7.6l-8.5-20.8c-7.8-19-14.6-39.8-20.2-61.8l-2.4-9.2l-9.3,1.9c-19.6,4-38.8,9.5-57,16.4
  l-14.8,5.6L101.5,416.4z M34.6,279.6c3.7,35.4,15.6,68.5,35.3,98.5l4.6,7l7.7-3.4c22.8-9.9,47.9-17.7,74.7-23.2l9.4-1.9l-1.5-9.5
  c-3.5-21.7-5.7-45-6.7-69.1l-0.4-9.6H33.4L34.6,279.6z M345.6,356.2c31.6,5.7,60.2,14,85,24.8l7.7,3.4l4.6-7
  c15-22.9,25.5-47.9,31.1-74.5l2.7-12.6l-12.9,0.6c-4.5,0.2-8.7,0.4-12.7,0.4c-32.1,0-62.8-7.2-91.2-21.5l-2.1-1.1h-13.6l-0.3,9.6
  c-0.8,22.2-2.9,44.6-6.3,66.6l-1.5,9.7L345.6,356.2z M183.4,279c0.8,21,2.9,42.6,6.4,64.4l1.5,9.4l9.5-1c18.3-2,37.3-3,56.4-3
  c13.6,0,28.1,0.6,44.4,2l9.3,0.8l1.4-9.2c3.4-22.5,5.4-43.2,6.2-63.3l0.4-10.4H183.1L183.4,279z M317.5,243.4l-15.6-16.8
  c-15.3-16.5-27.7-34.9-36.8-54.9l-2.7-5.8H256c-18.8,0-37.5-1-55.6-3l-9.5-1l-1.5,9.4c-3.2,20.4-5.2,41.2-6,61.7l-0.4,10.4H317.5z
   M157.7,243.2l0.3-9.6c0.8-23.3,2.8-44.9,6.1-66.2l1.5-9.5l-9.4-1.9c-27.8-5.6-53.2-13.5-75.6-23.5l-7.9-3.5l-4.6,7.3
  c-18.6,29.3-29.9,61.5-33.6,95.9l-1.2,11.1H157.7z M125.6,122.3c45.8,13.7,91.8,17.2,115.1,18l12.6,0.4l-2.5-12.4
  c-2.8-14.1-4.2-28.1-4.2-41.6c0-13.3,1.4-27.1,4.2-41.2l2.4-12h-13.6l-0.2,0c-49,1.7-93.7,28.9-95.6,30.1
  c-16.3,9.5-31.3,21-44.6,34.1l-11,10.8l14.4,5.6c5.6,2.2,10.6,4,15.1,5.5l0.7,0.2v0.3L125.6,122.3z" />
                <path style={{fill: '#FF3366'}} d="M400.3,188.7c-6.9,0-12.6-5.7-12.6-12.6v-106l-40.8,37c-2.3,2.3-5.4,3.6-8.7,3.6
  c-3.4,0-6.7-1.4-9.2-3.8c-2.4-2.4-3.7-5.6-3.7-9s1.3-6.6,3.7-9L392,30c2.4-2.4,5.6-3.7,9-3.7s6.6,1.3,9,3.7l61.1,61.1
  c2.4,2.4,3.7,5.6,3.7,9s-1.3,6.6-3.7,9c-2.4,2.4-5.6,3.7-9.1,3.7s-6.7-1.3-9.1-3.7L412.9,69v107.1
  C412.9,183,407.2,188.7,400.3,188.7z" />
              </g>
            </svg>
            <div>
              <p className="font-600 mb-1">Job Publishing</p>
              <ul className="d-flex list-unstyled flex-wrap ms-sm-n1">
                <li className="border-end border-light d-inline-block px-sm-1"><Link to="/career-site" className=" text-primary "> Career Site </Link></li>
                <li className="border-end border-light d-inline-block px-sm-1"><Link to="/job-fields" className=" text-primary "> Job Fields </Link></li>
                <li className="border-end border-light d-inline-block px-sm-1"><Link to="/job-embeds" className=" text-primary "> Job Embeds </Link></li>
                <li className="border-end border-light d-inline-block px-sm-1"><Link to="/social" className=" text-primary "> Social </Link></li>
                <li className="border-end border-light d-inline-block px-sm-1"><a href="listener.html" className=" text-primary "> Listener </a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-4 mt-3">
          <div className="d-flex align-items-start">
            <svg className="pe-2" style={{width: 45}} viewBox="0 0 512 512"><g>
                <path style={{fill: 'none'}} d="M341.2,150H158.6c6,11,9.5,24.8,9.5,40l0,13.6c0,0.6,0,1.3,0,1.9c0,0.2,0,0.3,0,0.5l0,237.1
  c20.6,6.4,42.6,9.9,65.3,9.9c48.2,0,92.7-15.5,128.9-41.8L362.2,190C362.2,167.1,351.1,150,341.2,150z" />
                <path style={{fill: 'none'}} d="M118.9,150.4c-1.8,0-5.7,4.2-8.9,15.7c-2.7,9.5-4.4,22.3-5,36.9h35.5c0.9,0,1.7,0.1,2.6,0.3v-0.1
  c-0.3-14.9-3.6-29.2-9.2-39.5C129.3,155.4,123.7,150.4,118.9,150.4z" />
              </g>
              <path style={{fill: '#2C3D4F'}} d="M320.7,259.4c0,5-4,9-9,9h-95.1c-5,0-9-4-9-9s4-9,9-9h95.1C316.7,250.4,320.7,254.4,320.7,259.4z
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     M311.7,297.8h-95.1c-5,0-9,4-9,9s4,9,9,9h95.1c5,0,9-4,9-9S316.7,297.8,311.7,297.8z M465.6,233.2c0,12.3-1,24.5-2.8,36.5
    c-7.5-1.3-15.3-2-23.2-2c-0.6,0-1.2,0-1.8,0c1.9-11.2,2.9-22.7,2.9-34.5C440.6,119,347.6,26,233.3,26S26.1,119,26.1,233.2
    c0,81.9,47.8,152.9,116.9,186.5l0-192c-0.8,0.2-1.7,0.3-2.6,0.3H92.2c-6.9,0-12.5-5.6-12.5-12.5c0-15.5,1.1-37.9,6.2-56.2
    c7.9-28,22.3-33.9,33-33.9c0,0,0,0,0,0c1-0.3,2.1-0.4,3.2-0.4h219.1c25.8,0,45.9,28.5,45.9,65l0,88.4c-8.9,3.8-17.2,8.5-25,14
    l0-102.4c0-22.9-11.1-40-20.9-40H158.6c6,11,9.5,24.8,9.5,40l0,13.6c0,0.6,0,1.3,0,1.9c0,0.2,0,0.3,0,0.5l0,223.9
    c20.5,6.8,42.5,10.6,65.3,10.6c26.5,0,51.9-5,75.3-14.2c1.6,8.4,4,16.5,7.1,24.2c-26,9.8-53.8,15-82.4,15c-62,0-120.3-24.2-164.2-68
    c-43.9-43.9-68-102.2-68-164.2S25.3,112.9,69.1,69S171.3,1,233.3,1s120.3,24.2,164.2,68S465.6,171.2,465.6,233.2z M143.1,203.3v-0.1
    c-0.3-14.9-3.6-29.2-9.2-39.5c-4.5-8.3-10.1-13.3-15-13.3c-1.8,0-5.7,4.2-8.9,15.7c-2.7,9.5-4.4,22.3-5,36.9h35.5
    C141.4,203,142.2,203.1,143.1,203.3z" />
              <path style={{fill: '#FF3366'}} d="M320.7,205c0,5-4,9-9,9h-95.1c-5,0-9-4-9-9s4-9,9-9h95.1C316.7,196,320.7,200,320.7,205z M483.6,430
    c-2.3,6.2-5.7,11.6-9.9,16l22,43.1l-38.1,19.5l-27-52.8c-0.7-0.2-1.5-0.5-2.2-0.7c-2.2,0.9-4.3,1.7-6.5,2.3l-26.2,51.3L357.7,489
    l24.1-47.1c-1.1-1.8-2.2-3.7-3.1-5.7c-11-4.1-19.3-11.5-23.9-21.2c-4.8-10.2-5-22-0.6-33.8c-4.4-11.1-4.6-22.2-0.4-31.9
    c4-9.3,11.7-16.4,21.9-20.7c4.2-10.2,11.4-17.8,20.7-21.9c9.6-4.2,20.6-4,31.5,0.2c10.4-4.3,20.9-4.7,30.1-0.9
    c8.9,3.6,15.9,10.7,20.2,20.4c10.8,4.2,19,11.5,23.4,21.1c4.6,10,4.7,21.5,0.5,33.1c4.4,10.1,4.9,20.4,1.3,29.5
    C499.9,418.8,493,425.7,483.6,430z M477.4,387.4c-2.2-3.7-2.3-8.2-0.4-12c3.3-6.5,3.9-12.7,1.8-17.3c-2-4.4-6.7-7.8-13.2-9.3
    c-4.4-1.1-7.8-4.4-9.1-8.7c-1.6-5.5-4.4-9.3-7.9-10.7c-3.8-1.5-8.8-0.6-14.3,2.5c-3.8,2.1-8.3,2.2-12.2,0.2
    c-3.9-2.1-7.6-3.1-10.8-3.1c-1.8,0-3.5,0.3-5,1c-4,1.7-7.1,6-8.7,11.9c-1.2,4.3-4.5,7.7-8.9,8.9c-6,1.6-10.2,4.7-11.9,8.7
    c-1.8,4.2-1.1,9.8,2.2,15.8c1.9,3.6,2,7.9,0.2,11.5c-3.3,6.7-3.9,13-1.7,17.8c2.2,4.6,7,8,13.7,9.5c4.4,1,7.9,4.3,9.2,8.7
    c1.6,5.3,4.3,9,7.7,10.3c3.6,1.4,8.5,0.5,13.8-2.6c3.6-2.1,8.1-2.3,11.9-0.4c6.8,3.3,13.2,3.9,18,1.6c4.7-2.2,8.1-7.1,9.6-13.9
    c1-4.4,4.3-7.9,8.7-9.2c5.2-1.5,8.8-4.2,10.1-7.5C481.3,397.4,480.4,392.6,477.4,387.4z" />
            </svg>
            <div>
              <p className="font-600 mb-1">Offers</p>
              <ul className="d-flex list-unstyled flex-wrap ms-sm-n1">
                <li className="border-end border-light d-inline-block px-sm-1"><NavLink to="/templates" className=" text-primary "> Templates </NavLink></li>
                <li className="border-end border-light d-inline-block px-sm-1"><NavLink to="/fields" className=" text-primary "> Fields </NavLink></li>
                <li className="border-end border-light d-inline-block px-sm-1"><NavLink to="/decline-reason" className=" text-primary "> Decline Reasons </NavLink></li>
                <li className="border-end border-light d-inline-block px-sm-1"><a href="#" className=" text-primary "> Approval Rules </a></li>
                <li className="border-end border-light d-inline-block px-sm-1"><NavLink to="/approval-reject" className=" text-primary "> Approval Reject Reasons </NavLink></li>
              </ul>
            </div>
          </div>
        </div>                                           
      </div>
        </>
    )
}

export default Recruitment
