import React from 'react'

function Offer() {
  
    return (
        <>
            <h4 className="text-info ps-1 mt-4">Offer</h4>
                      <li className="list-group-item list-group-item-action position-relative">
                        <div className="show-btns">
                          <div className="form-check form-checkbox-success form-switch">
                            <input 
                                type="checkbox" 
                                className="form-check-input" 
                                id="customSwitch5" 
                                defaultChecked 
                              />
                            <label className="form-check-label" htmlFor="customSwitch5">Candidate offered</label>
                          </div>
                          <div className="hover-btns position-absolute" id="tooltip-container2">
                            <i className=" dripicons-document-edit align-middle me-2" /> 
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item list-group-item-action position-relative">
                        <div className="show-btns">
                          <div className="form-check form-checkbox-success form-switch">
                            <input 
                                type="checkbox" 
                                className="form-check-input" 
                                id="customSwitch5" 
                                defaultChecked 
                                />
                            <label className="form-check-label" htmlFor="customSwitch5">Candidate accepted the offer</label>
                          </div>
                          <div className="hover-btns position-absolute" id="tooltip-container2">
                            <i className=" dripicons-document-edit align-middle me-2" /> 
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item list-group-item-action position-relative">
                        <div className="show-btns">
                          <div className="form-check form-checkbox-success form-switch">
                            <input 
                                type="checkbox" 
                                className="form-check-input" 
                                id="customSwitch5" 
                                defaultChecked 
                                />
                            <label className="form-check-label" htmlFor="customSwitch5">Candidate declined the offer</label>
                          </div>
                          <div className="hover-btns position-absolute" id="tooltip-container2">
                            <i className=" dripicons-document-edit align-middle me-2" /> 
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item list-group-item-action position-relative">
                        <div className="show-btns">
                          <div className="form-check form-checkbox-success form-switch">
                            <input 
                                type="checkbox" 
                                className="form-check-input" 
                                id="customSwitch5" 
                                defaultChecked 
                                />
                            <label className="form-check-label" htmlFor="customSwitch5">Request to E-sign an Offer Letter (Sent to signers)</label>
                          </div>
                          <div className="hover-btns position-absolute" id="tooltip-container2">
                            <i className=" dripicons-document-edit align-middle me-2" /> 
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item list-group-item-action position-relative">
                        <div className="show-btns">
                          <div className="form-check form-checkbox-success form-switch">
                            <input 
                              type="checkbox" 
                              className="form-check-input" 
                              id="customSwitch5" 
                              defaultChecked 
                              />
                            <label className="form-check-label" htmlFor="customSwitch5">A Signer E- signed the Offer letter</label>
                          </div>
                          <div className="hover-btns position-absolute" id="tooltip-container2">
                            <i className=" dripicons-document-edit align-middle me-2" /> 
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item list-group-item-action position-relative">
                        <div className="show-btns">
                          <div className="form-check form-checkbox-success form-switch">
                            <input 
                                type="checkbox" 
                                className="form-check-input" 
                                id="customSwitch5" 
                                defaultChecked 
                                />
                            <label className="form-check-label" htmlFor="customSwitch5">A Signer Declined to sign the Offer Letter</label>
                          </div>
                          <div className="hover-btns position-absolute" id="tooltip-container2">
                            <i className=" dripicons-document-edit align-middle me-2" /> 
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item list-group-item-action position-relative">
                        <div className="show-btns">
                          <div className="form-check form-checkbox-success form-switch">
                            <input 
                                type="checkbox" 
                                className="form-check-input" 
                                id="customSwitch5" 
                                defaultChecked 
                                />
                            <label className="form-check-label" htmlFor="customSwitch5">Offer E-Signature Voided (Sent to signers)</label>
                          </div>
                          <div className="hover-btns position-absolute" id="tooltip-container2">
                            <i className=" dripicons-document-edit align-middle me-2" /> 
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item list-group-item-action position-relative">
                        <div className="show-btns">
                          <div className="form-check form-checkbox-success form-switch">
                            <input 
                                type="checkbox" 
                                className="form-check-input" 
                                id="customSwitch5"
                                 />
                            <label className="form-check-label" htmlFor="customSwitch5">All signers have E-signed an Offer Letter</label>
                          </div>
                          <div className="hover-btns position-absolute" id="tooltip-container2">
                            <i className=" dripicons-document-edit align-middle me-2" /> 
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item list-group-item-action position-relative">
                        <div className="show-btns">
                          <div className="form-check form-checkbox-success form-switch">
                            <input 
                                type="checkbox" 
                                className="form-check-input" 
                                id="customSwitch5" 
                                defaultChecked 
                                />
                            <label className="form-check-label" htmlFor="customSwitch5">Offer needs your approval (Sent to approvers)</label>
                          </div>
                          <div className="hover-btns position-absolute" id="tooltip-container2">
                            <i className=" dripicons-document-edit align-middle me-2" /> 
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item list-group-item-action position-relative">
                        <div className="show-btns">
                          <div className="form-check form-checkbox-success form-switch">
                            <input 
                                type="checkbox" 
                                className="form-check-input" 
                                id="customSwitch5" 
                                defaultChecked 
                                />
                            <label className="form-check-label" htmlFor="customSwitch5">Offer approval reminder (Sent to approvers)</label>
                          </div>
                          <div className="hover-btns position-absolute" id="tooltip-container2">
                            <i className=" dripicons-document-edit align-middle me-2" /> 
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item list-group-item-action position-relative">
                        <div className="show-btns">
                          <div className="form-check form-checkbox-success form-switch">
                            <input 
                                type="checkbox" 
                                className="form-check-input" 
                                id="customSwitch5" 
                                defaultChecked 
                                />
                            <label className="form-check-label" htmlFor="customSwitch5">New offer comment posted</label>
                          </div>
                          <div className="hover-btns position-absolute" id="tooltip-container2">
                            <i className=" dripicons-document-edit align-middle me-2" /> 
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item list-group-item-action position-relative">
                        <div className="show-btns">
                          <div className="form-check form-checkbox-success form-switch">
                            <input 
                                type="checkbox" 
                                className="form-check-input" 
                                id="customSwitch5" 
                                defaultChecked 
                                />
                            <label className="form-check-label" htmlFor="customSwitch5">You were mentioned in offer comment</label>
                          </div>
                          <div className="hover-btns position-absolute" id="tooltip-container2">
                            <i className=" dripicons-document-edit align-middle me-2" /> 
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item list-group-item-action position-relative">
                        <div className="show-btns">
                          <div className="form-check form-checkbox-success form-switch">
                            <input 
                                type="checkbox" 
                                className="form-check-input" 
                                id="customSwitch5" 
                                defaultChecked 
                              />
                            <label className="form-check-label" htmlFor="customSwitch5">Offer info updated</label>
                          </div>
                          <div className="hover-btns position-absolute" id="tooltip-container2">
                            <i className=" dripicons-document-edit align-middle me-2" /> 
                          </div>
                        </div>
                      </li>   
        </>
    )
}

export default Offer
