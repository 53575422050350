import React, { useState, useEffect } from "react";
import axios from "../common/axios";
import Protected from "../common/Protected";
import Dashboard from "../component/Dashboard";
import Recruite from "../component/recruitment/Recruite";
import Conversation from "../component/recruitment/Conversation";
import JobPosting from "../component/JobPosting";
import Candidates from "../component/Candidates";
import TalentPool from "../component/TalentPool";
import Hire from "../component/hiring/Hire";
import Settings from "../component/settings/Settings";
import Location from "../component/location/Location";
import Department from "../component/department/Department";
import SubDepartment from "../component/department/SubDepartment";
import Company from "../component/company/Company";
import DeclineReason from "../component/offer/DeclineReason";
import ApprovalReject from "../component/offer/ApprovalReject";
import Employee from "../component/employee/Employee";
import EditEmployee from "../component/employee/EditEmployee";
import EmployeeList from "../component/employee/EmployeeList";
import CandidateDash from "../component/CandidateDash";
import NewTask from "../component/newTask/NewTask";
import Tag from "../component/tags/Tag";
import { useHistory, Redirect } from "react-router-dom";
import ManageRole from "./roles/ManageRole";
import Email from "./email/Email";
import Csv from "./csv/index";
import Team from "./teams/Team";
import Preference from "./preference/Preference";
import JobField from "./jobfield/JobField";
import Business from "./business/Business";
import Vendors from "./vendors/Vendors";
import CandidateReject from "./candidateRejectReason/CandidateReject";
import Template from "./template/Template";
import JobEmbed from "./jobEmbed/JobEmbed";
import Social from "./social/Social";
import Integration from "./integration/Integration";
import Csv2 from "./csv2/Csv2";
import Account from "./account/Account";
import Fields from "./Fields/Fields";
import CareerSite from "./career-site/CareerSite";
import EmailNotification from "./notification/EmailNotification";
function Load() {
  const [location, setLocation] = useState([]);
  const [dept, setDept] = useState([]);
  const [job, setJob] = useState([]);
  const [role, setRole] = useState([]);
  const [state, setState] = useState({});
  // const history = useHistory();

  useEffect(() => {
    let token = localStorage.getItem("login");
    let id = "60cc9d5c831a4353031c620d";
    axios
      .get(`company/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        console.log(res.data.results);
        setState(res.data.results);
      })
      .catch((err) => {
        console.log(err.message);
        // if(err.message==="Request failed with status code 403")
        // {
        //   localStorage.clear();
        //   history.push("/");
        // }
      });
  }, []);

  // useEffect(()=>{
  //   console.log('hi')
  //      setInterval(()=>{
  //       localStorage.clear();
  //       <Redirect to="/login" />
  //      },1000*60*60)
  //  },[])

  // useEffect(

  //   () => {

  //     let timer1 = setInterval(() =>{
  //       localStorage.removeItem('login')
  //       console.log('logout')
  //      // history.push('/login')
  //     }, 1000*60);

  //     return () => {
  //       clearTimeout(timer1);
  //     };

  //   },

  //   []
  // );

  useEffect(() => {
    // setTimeout(()=>{
    const token = localStorage.getItem("login");
    axios
      .get("location", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        console.log(res.data.results);
        setLocation(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
    // },25000)
  }, []);

  useEffect(() => {
    console.log("dept1");
    // setTimeout(()=>{
    const token = localStorage.getItem("login");
    axios
      .get("department", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        console.log("dept", res.data.results);
        setDept(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
    // },25000)
  }, []);

  useEffect(() => {
    //   setTimeout(()=>{
    const token = localStorage.getItem("login");
    axios
      .get("job", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        console.log("job", res.data.results);
        setJob(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
    //   },25000)
  }, []);

  useEffect(() => {
    //   setTimeout(()=>{
    const token = localStorage.getItem("login");
    axios
      .get("role", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        // console.log('job',res.data.results)
        setRole(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    //   },25000)
  }, []);

  return (
    <>
      <Protected path="/" exact>
        <Dashboard headerData={state} />
      </Protected>
      <Protected path="/hire/:id" exact>
        <Hire data={job} headerData={state} />
      </Protected>
      <Protected path="/recruitment/recruite" exact>
        <Recruite data={job} headerData={state} />
      </Protected>
      <Protected path="/recruitment/conversation" exact>
        <Conversation headerData={state} />
      </Protected>
      <Protected path="/job/posting" exact>
        <JobPosting headerData={state} />
      </Protected>
      <Protected path="/candidates" exact>
        <Candidates headerData={state} />
      </Protected>
      <Protected path="/talent/pool" exact>
        <TalentPool headerData={state} />
      </Protected>
      <Protected path="/settings" exact>
        <Settings headerData={state} />
      </Protected>
      <Protected path="/locations" exact>
        <Location data={location} headerData={state} />
      </Protected>
      <Protected path="/departments" exact>
        <Department data={dept} headerData={state} />
      </Protected>
      <Protected path="/sub-departments" exact>
        <SubDepartment headerData={state} />
      </Protected>
      <Protected path="/company" exact>
        <Company headerData={state} />
      </Protected>
      <Protected path="/decline-reason" exact>
        <DeclineReason headerData={state} />
      </Protected>
      <Protected path="/approval-reject" exact>
        <ApprovalReject headerData={state} />
      </Protected>
      <Protected path="/employee" exact>
        <Employee headerData={state} />
      </Protected>
      <Protected path="/employee-edit/:id" exact>
        <EditEmployee headerData={state} />
      </Protected>
      <Protected path="/employee-list" exact>
        <EmployeeList headerData={state} />
      </Protected>
      <Protected path="/candidate-dashboard" exact>
        <CandidateDash headerData={state} />
      </Protected>
      <Protected path="/new-task" exact>
        <NewTask headerData={state} />
      </Protected>
      <Protected path="/tags" exact>
        <Tag headerData={state} />
      </Protected>
      <Protected path="/manage/roles" exact>
        <ManageRole data={role} headerData={state} />
      </Protected>
      <Protected path="/email" exact>
        <Email headerData={state} />
      </Protected>
      <Protected path="/csv" exact>
        <Csv headerData={state} />
      </Protected>
      <Protected path="/teams" exact>
        <Team headerData={state} />
      </Protected>
      <Protected path="/Preferences" exact>
        <Preference headerData={state} />
      </Protected>
      <Protected path="/job-fields" component={JobField} exact>
        <JobField headerData={state} />
      </Protected>
      <Protected path="/business-units" exact>
        <Business headerData={state} />
      </Protected>
      <Protected path="/vendors" exact component={Vendors}>
        <Vendors headerData={state} />
      </Protected>
      <Protected path="/candidate-reject-reason" exact>
        <CandidateReject headerData={state} />
      </Protected>
      <Protected path="/templates" exact>
        <Template headerData={state} />
      </Protected>
      <Protected path="/job-embeds" exact>
        <JobEmbed headerData={state} />
      </Protected>
      <Protected path="/social" exact>
        <Social headerData={state} />
      </Protected>
      <Protected path="/integrations" exact>
        <Integration headerData={state} />
      </Protected>
      <Protected path="/csv-file2" exact>
        <Csv2 headerData={state} />
      </Protected>
      <Protected path="/account" exact>
        <Account headerData={state} />
      </Protected>
      <Protected path="/fields" exact>
        <Fields headerData={state} />
      </Protected>
      <Protected path="/career-site" exact>
        <CareerSite headerData={state} />
      </Protected>
      <Protected path="/email-notifications" exact>
        <EmailNotification headerData={state} />
      </Protected>
    </>
  );
}

export default Load;
